.content {
  padding: 20px;
  overflow-x: auto;
  overflow-y: hidden;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  width: 100%;
}
