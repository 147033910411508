.login-page {
  align-items: center;
  background-color: #f8f8f9;
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}
.login-page .login-page-left-part {
  background-color: rgb(23, 41, 131);
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  width: 50vw;
}
.login-page .login-page-left-part-overlay {
  background: linear-gradient(
    99deg,
    rgba(26, 41, 129, 0.6) 0.66%,
    rgba(3, 11, 66, 0.6)
  );
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 50vw;
  z-index: 0;
}
.login-page .login-page-left-part .login-page-left-part-logo {
  margin-left: 85px;
  margin-top: 25px;
  z-index: 2;
}
.login-page .login-page-left-part .login-page-left-part-punchline {
  align-items: center;
  color: #fff;
  display: flex;
  font-size: 64px;
  font-weight: 500;
  height: calc(100% - 54px);
  line-height: 75px;
  margin-left: 85px;
  width: 400px;
  z-index: 2;
}
.login-page .login-page-right-part {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  width: 50vw;
}
.login-page .login-page-right-part .login-page-auth-part {
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(3, 7, 28, 0.14);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 210px;
  justify-content: center;
  width: 404px;
}
.login-page .login-page-auth-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}
.login-page .login-page-auth-logo {
  margin-bottom: 20px;
  margin-top: 5px;
}
.vallourec-logo {
  width: 110px;
  height: 35px;
  padding: 1px;
  border-radius: 5px;
}
